import { createSlice } from '@reduxjs/toolkit';
// axios
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  manifest: { Categories: [] },
  userConsents: {},
  isLoading: true,
  error: false,
  isTutorialRequired: true,
  userClient: '',
  userEmail: '',
  userForm: ''
};

const slice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    toggleTutorialView(state, action) {
      state.isTutorialRequired = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANIFEST SUCCESS
    getQuestionnaireManifestSuccess(state, action) {
      state.manifest = action.payload;
      state.isLoading = false;
    },

    updateUserConsents(state, action) {
      const { username, questionnaireId } = action.payload;
      const existingUsernames = state.userConsents[questionnaireId];
      state.userConsents = {
        ...state.userConsents,
        [questionnaireId]: existingUsernames
          ? [...existingUsernames, username]
          : [username]
      };
    },

    updateUser(state, action) {
      const { clientName, username, form } = action.payload;
      state.userClient = clientName;
      state.userEmail = username;
      state.userForm = form;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  updateUserConsents,
  toggleTutorialView,
  updateUser
} = slice.actions;

// ----------------------------------------------------------------------

export function getQuestionnaireManifest(questionnaire, accessToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/questionnaire/manifest/${questionnaire}`,
        {
          headers: { ...getAuthorizationHeader(accessToken) }
        }
      );
      dispatch(
        slice.actions.getQuestionnaireManifestSuccess(response.data.Data[0])
      );
    } catch (error) {
      console.error(`Error:`, error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function getEmailValidation(accessToken, token) {
  try {
    const response = await axios.get(
      `/questionnaire/channel/email/validate?Token=${token}`,
      {
        headers: { ...getAuthorizationHeader(accessToken) }
      }
    );
    return response;
  } catch (error) {
    return { ...error };
  }
}

// ----------------------------------------------------------------------

export async function submitQuestionnaire(payload, token, accessToken) {
  try {
    const response = await axios.post(
      `/questionnaire/channel/email?Token=${token}`,
      { ...payload },
      {
        headers: { ...getAuthorizationHeader(accessToken) }
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error:`, error);
    return { ...error };
  }
}

function getAuthorizationHeader(accessToken) {
  return {
    Authorization: `Bearer ${accessToken}`
  };
}
