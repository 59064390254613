import axios from 'axios';
import { apiBaseUrl } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: apiBaseUrl
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(
      (error.response || {}).data || 'Something went wrong'
    );
  }
);

export default axiosInstance;
