import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
// slices
import questionnaireReducer from './slices/questionnaire';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const questionnairePersistConfig = {
  key: 'questionnaire',
  storage,
  keyPrefix: 'questionnaire-',
  whitelist: [
    'userConsents',
    'isTutorialRequired',
    'userClient',
    'userEmail',
    'userForm'
  ]
};

const rootReducer = combineReducers({
  questionnaire: persistReducer(
    questionnairePersistConfig,
    questionnaireReducer
  )
});

export { rootPersistConfig, rootReducer };
