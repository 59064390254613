// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  return (
    <RootStyle {...other}>
      <CircularProgress />
    </RootStyle>
  );
}
