// utils
import mock from './mock';

// ----------------------------------------------------------------------

const manifests = {
  WLQ: {
    QuestionnaireId: 'WLQ',
    Categories: [
      {
        Name: 'Time demands',
        QuestionPreface:
          'In the past 2 weeks, how much of the time did your physical health or emotional problems made it difficult for you to do the following?',
        Questions: [
          {
            Type: 'slider',
            Value: 'Work required hours',
            QuestionId: 'DCWWLQ001',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Get going at the beginning of work day',
            QuestionId: 'DCWWLQ002',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Start on work soon after arriving',
            QuestionId: 'DCWWLQ003',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Work without breaks or rests',
            QuestionId: 'DCWWLQ004',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Stick to routine/schedule',
            QuestionId: 'DCWWLQ005',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          }
        ]
      },
      {
        Name: 'Physical demands',
        QuestionPreface:
          'In the past 2 weeks, how much of the time did your physical health or emotional problems made it difficult for you to do the following?',
        Questions: [
          {
            Type: 'slider',
            Value: 'Walk/move around work location',
            QuestionId: 'DCWWLQ006',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value:
              'Sit, stand or stay in one position for longer than 15 minutes while working',
            QuestionId: 'DCWWLQ007',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Lift, carry or move objects over 5 kilos',
            QuestionId: 'DCWWLQ008',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Tilt, turn or stretch to reach objects at work',
            QuestionId: 'DCWWLQ009',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Repeat the same motions while working',
            QuestionId: 'DCWWLQ0010',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value:
              'Use hand-held equipements (phone, pen, keyboard, mouse etc)',
            QuestionId: 'DCWWLQ0011',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          }
        ]
      },
      {
        Name: 'Mental demands',
        QuestionPreface:
          'In the past 2 weeks, how much of the time did your physical health or emotional problems made it difficult for you to do the following?',
        Questions: [
          {
            Type: 'slider',
            Value: 'Think clearly at work',
            QuestionId: 'DCWWLQ0012',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Work carefully',
            QuestionId: 'DCWWLQ0013',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Concentrate on work',
            QuestionId: 'DCWWLQ0014',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Maintain a train of thought at work',
            QuestionId: 'DCWWLQ0015',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Read or see easily at work',
            QuestionId: 'DCWWLQ0016',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Keep mind on work',
            QuestionId: 'DCWWLQ0017',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          }
        ]
      },
      {
        Name: 'Interpersonal demands',
        QuestionPreface:
          'In the past 2 weeks, how much of the time did your physical health or emotional problems made it difficult for you to do the following?',
        Questions: [
          {
            Type: 'slider',
            Value: 'Speak in person/over the phone',
            QuestionId: 'DCWWLQ0018',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Help others at work',
            QuestionId: 'DCWWLQ0019',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Control your temper at work',
            QuestionId: 'DCWWLQ0020',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          }
        ]
      },
      {
        Name: 'Output demands',
        QuestionPreface:
          'In the past 2 weeks, how much of the time did your physical health or emotional problems made it difficult for you to do the following?',
        Questions: [
          {
            Type: 'slider',
            Value: 'Handle the workload',
            QuestionId: 'DCWWLQ0021',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Work at sufficient speed',
            QuestionId: 'DCWWLQ0022',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Finish work on time',
            QuestionId: 'DCWWLQ0023',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Work without making mistakes',
            QuestionId: 'DCWWLQ0024',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          },
          {
            Type: 'slider',
            Value: 'Do all you are capable of',
            QuestionId: 'DCWWLQ0025',
            QuestionnaireId: 'WLQ',
            Answers: [
              {
                Id: '1',
                Key: '84798941-c154-4dae-8143-fa08ded419b5',
                Value: 'Does not apply to my job'
              },
              {
                Id: '2',
                Key: 'da6cc7a0-ff8b-4d66-9422-55ed5ffcf0ee',
                Value: 'None of the time'
              },
              {
                Id: '3',
                Key: '77bbf760-93eb-4f96-838c-d24d15f3c7f8',
                Value: 'A slight amount of time'
              },
              {
                Id: '4',
                Key: '8d7a49ec-0faf-45ed-bfa9-ae23ca71f5d8',
                Value: 'Some of the time'
              },
              {
                Id: '5',
                Key: '5fec3533-39fd-480d-88d0-3414f25ab0c4',
                Value: 'Most of the time'
              },
              {
                Id: '6',
                Key: '95d4e42c-c929-477c-aebe-a9482af73e0f',
                Value: 'All of the time'
              }
            ]
          }
        ]
      }
    ],
    DisclaimerId: 1,
    DisclaimerVersion: '1.0.0',
    DisclaimerTitle: 'Work Limitations',
    DisclaimerDescription:
      'The following survey explores the health, relationships, environment, security and the purpose in work to improve wellbeing.',
    DisclaimerAcceptanceDescription:
      'By accepting, you agree to our Terms & Conditions and that you have read our Data Use Policy.',
    DisclaimerAcceptanceRequired: true,
    SuccessNote:
      'Thank you for taking the time to answer the Questions! We truly value the information you have provided.'
  }
};

mock.onGet(/^\/api\/questionnaire\/manifest\/[^/]+/).reply((request) => {
  const questionnaireId = request.url.split('/').slice(-1)[0];
  if (manifests[questionnaireId]) {
    return [200, { Data: [manifests[questionnaireId]] }];
  }
  return [404, { Data: [] }];
});

mock
  .onPost(/^\/api\/questionnaire\/channel\/email\?Token=.+/)
  .reply(() => [200, { Data: [], StatusCode: 200 }]);

mock
  .onGet(/^\/api\/questionnaire\/channel\/email\/validate\?Token=.+/)
  .reply(() =>
    // [400, { error: 'Survay has been already submited!' }]
    [200, { Data: [], StatusCode: 200 }]
  );
